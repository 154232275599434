<template>
  <v-app>
    <SideNav />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideNav from "./components/SideNav";

export default {
  name: "App",

  components: {
    SideNav,
  },
};
</script>
